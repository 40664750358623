import axios from 'axios';
import React, { useState } from "react"; // Import React library
import { toast, ToastContainer } from "react-toastify"; // Import pop-up notification component
import { Link } from "react-router-dom"; // Import Link component from react-router-dom for navigation

import { FAX_SERVER } from '../App';
import logo from "../mcnonline_logo.png"; // Import the logo image


const Navbar = ({ loginId, logOut }) => {
  const [logOutInProgress, setLogOutInProgress] = useState(false);

  /**
   * Handle the logout process.
   */
  const handleLogout = async () => {
    const path = `${FAX_SERVER}/rest/nsx/Logout?LoginId=${loginId}`;

    // Prevent multiple submissions
    if (logOutInProgress)
      return;
    setLogOutInProgress(true);

    // Send the logout request
    try {
      popUp("Logging out...", "info");
      await axios.get(path);
      console.log("Logged out");
      popUp("Logged out!", "success");
      logOut();
    }
    catch (error) {
      console.error("Logout failed:", error.message);
      popUp(`Logout failed! ${error.message}`, "error");
    }

    setLogOutInProgress(false); // Allow clicking the logout button again - only relevant if an error occurs
  };

  /**
   * Display a pop-up message.
   * @param {string} message - The message to display.
   * @param {string} type - The type of toast to display.
   */
  const popUp = (message, type) => {
    toast.dismiss(); // Close existing pop-ups
    toast(message, { type: type }); // Pop-up
  }

  return (
    <nav className="navbar navbar-dark bg-dark">
      {/* Link component for the brand/logo, navigates to the home page */}
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="MCN Online" className="navbar-logo" /> {/* Use the logo image */}
      </Link>

      {/* Navigation links */}
      <div className="navbar-nav flex-column">
        {/* Received Faxes Page */}
        <Link className="nav-link" to="/inbox">
          Inbox
        </Link>

        {/* Send Fax Page */}
        <Link className="nav-link" to="/sendfax">
          Send Fax
        </Link>

        {/* Sent Faxes Pages */}
        <Link className="nav-link" to="/sent">
          Sent Faxes
        </Link>

        {/* Logout Button */}
        <Link className="nav-link" onClick={handleLogout}>
          Log Out
        </Link>
      </div>

      {/* Container needed for pop-up messages */}
      <ToastContainer/>
    </nav>
  );
};

export default Navbar;
