
import axios from 'axios';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { FAX_SERVER } from '../App';

import { FaDownload, /*FaTrash*/ } from 'react-icons/fa'; // Import icons from react-icons


const FaxList = ({ loginId, keys, type }) => {
  const [messageQueues, setMessageQueues] = useState(keys.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {})); // Map with each key's value an empty array
  const [messages, setMessages] = useState({}); // Cache for message data

  // Refresh message queues every second
  useEffect(() => {
    /**
     * Loads and caches the message data for a given message handle.
     * @param {String} messageHandle - The message handle to cache.
     */
    const cacheMessage = async (messageHandle) => {
      try {
        const path = `${FAX_SERVER}/rest/mqs/Messages/ReadMessage?LoginId=${loginId}&MessageHandle=${messageHandle}`;
        const messageData = (await axios.get(path)).data;
        console.log("Loaded message data:", messageData);

        // Cache the message data
        let messageMap = messages;
        messageMap[messageHandle] = messageData;
        setMessages(messageMap);
      } catch (e) {
        console.error("Message data retrieval failed:", e.message);
      }
    }

    /**
     * Refreshes the message queues.
     */
    const refreshMessageQueues = async () => {
      let queues = { ...messageQueues };

      // Go through each message queue (Sending, Sent, etc.)
      for (let key in queues) {
        const path = `${FAX_SERVER}/rest/mqs/Messages/ReadQueue?Queue=${key}&LoginId=${loginId}`;

        // Get the message queue
        try {
          const messageHandles = (await axios.get(path)).data.MessageHandles;
          queues[key] = (messageHandles != null) ? messageHandles.split(",") : [];

          // Get the message data for each message handle
          for (let i = 0; i < queues[key].length; ++i) {
            let messageHandle = queues[key][i];
            if (messages[messageHandle] == null) {
              await cacheMessage(messageHandle);
            }
          }
        }
        catch (e) {
          console.error("Message queues retrieval failed:", e.message);
          return;
        }
      }

      setMessageQueues(queues);
    };

    refreshMessageQueues();
    const interval = setInterval(refreshMessageQueues, 1000);

    return () => clearInterval(interval);
  }, [loginId, type, messageQueues, messages]); // Only run when the login id or keys change

  /**
   * Downloads a fax as a PDF.
   * @param {String} messageHandle - The message handle of the fax to download.
   */
  const downloadFax = async (messageHandle) => {
    try {
      // Send a POST request to download the fax
      // GET requsts distort the data, so we need to use POST
      const path = `${FAX_SERVER}/rest/mqs/Messages/BuildFaxImage?LoginId=${loginId}`;
      const requestData = { MessageHandle: messageHandle, DocumentType: "PDF" };
      const response = await axios.post(path, requestData,
        {
          headers: { "Content-Type": "application/json" },
        });
      const faxData = response.data.FaxImage.ContentData;
      console.log("Downloaded fax data", response);

      // Choose the file name
      const extension = "pdf";
      let fileName = `fax-${messageHandle}.${extension}`;
      if (messageHandle in messages) {
        const message = messages[messageHandle].Message;
        if (message.Subject)
          fileName = message.Subject + `.${extension}`;
      }

      // Decode the base64 data and download the fax
      const buffer = Buffer.from(faxData, "base64");
      const blob = new Blob([buffer], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const element = document.createElement("a");
      element.href = url;
      element.download = fileName;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (e) {
      console.error("Fax download failed:", e.message);
    }
  }

  /**
   * Deletes a fax.
   * @param {String} messageHandle - The message handle of the fax to delete.
   */
  /*const deleteFax = async (messageHandle) => {
    try {
      const path = `${FAX_SERVER}/rest/mqs/Messages/DeleteMessage?LoginId=${loginId}`;
      const requestData = { MessageHandle: messageHandle };
      await axios.post(path, requestData,
        {
          headers: { "Content-Type": "application/json" },
        });
      console.log("Deleted fax", messageHandle);
    } catch (e) {
      console.error("Fax deletion failed:", e.message);
    }
  }*/

  /**
   * Converts the message queues object into an array of objects for display in the table
   * @returns {Array} An array of objects representing the message queues.
   *                  Each object represents a row in the table.
   *                  Eg. [{Send: "", Sending: "", Sent: "", Receiving: "", Received: ""}, ...]
   */
  const getMessageQueues = () => {
    // Start by finding the longest queue
    let maxLength = 0;
    for (let key in messageQueues) {
      if (messageQueues[key].length > maxLength)
        maxLength = messageQueues[key].length;
    }

    // Create an array of objects to represent the queues
    let queues = [];
    for (let i = 0; i < maxLength; ++i) {
      let queue = {};

      // Add the message titles to the queue
      for (let key in messageQueues) {
        queue[key] = "";
        if (messageQueues[key][i])
          queue[key] = prettyPrintMessageHandle(messageQueues[key][i]);
      }

      queues.push(queue);
    }

    return queues; // Return an array so each row can be entered in a table
  }

  /**
   * Converts a message into a pretty message.
   * @param {String} messageHandle - The message handle of the message.
   * @returns {String} The prettified message.
   */
  const prettyPrintMessageHandle = (messageHandle) => {
    // MODIFY THIS FUNCTION TO CHANGE WHAT'S DISPLAYED IN THE TABLE
    let prettyMessage;
    let messageData = messages[messageHandle];

    if (type === "inbox")
      prettyMessage = prettyPrintInboxMessageHandle(messageHandle, messageData);
    else
      prettyMessage = prettyPrintSentMessageHandle(messageHandle, messageData);

    // Split the text into multiple lines
    if (prettyMessage.includes("\n")) {
      prettyMessage =
        <div>
          {
            // Split the name by newlines and display each line
            prettyMessage.split("\n").map((line, index) => {
              return <div key={index}>{line}</div>;
            })
          }
        </div>
    }

    // Add a download icon if the message has a document
    if (messageData.Message.Document) {
      prettyMessage =
        <div className="d-flex">
          <div className="icon-container icon-download" onClick={async () => await downloadFax(messageHandle)}>
            <FaDownload style={{ color: '#fff' }} />
          </div>
          {prettyMessage}
          {/*<div className="icon-container icon-trash" onClick={async () => await deleteFax(messageHandle)}>
            <FaTrash style={{ color: '#fff' }} />
          </div>*/}
        </div>;
    }

    return prettyMessage;
  }

  /**
   * Converts a received message into a pretty message.
   * @param {String} messageHandle - The message handle of the message.
   * @param {Object} messageData - The message data.
   * @returns {String} The prettified message.
   */
  const prettyPrintInboxMessageHandle = (messageHandle, messageData) => {
    // Default to the message handle if no data is found
    if (messageData == null || messageData.Message.Recipient == null)
      return messageHandle;

    // Validate the data needed to display is present
    messageData = messageData.Message.Recipient;
    let senderName = messageData.RemoteCSId;
    let senderNumber = messageData.CallerId;
    let sendTime = messageData.StartTime;

    if (!senderName && !senderNumber && !sendTime)
      return "Unknown Sender";

    // Format the message. Example:
    /*
     * 1234567890 (John Doe)
     * 12/31/2021, 12:00:00 PM
     */
    let prettyMessage = "";
    if (senderNumber)
      prettyMessage += `${senderNumber} `;
    if (senderName)
      prettyMessage += `(${senderName})`;
    if (sendTime && !isNaN(Date.parse(sendTime))) {
      sendTime = new Date(sendTime);
      sendTime = sendTime.toLocaleString('en-US', { timeZone: 'America/New_York' }); // EST/EDT
      prettyMessage += `\n${sendTime}`;
    }

    return prettyMessage;
  }

  /**
   * Converts a sent message into a pretty message.
   * @param {String} messageHandle - The message handle of the message.
   * @param {Object} messageData - The message data.
   * @returns {String} The prettified message.
   */
  const prettyPrintSentMessageHandle = (messageHandle, messageData) => {
    // Default to the message handle if no data is found
    let prettyMessage = messageHandle;
    if (messageData == null)
      return prettyMessage;

    // Validate the data needed to display is present
    messageData = messageData.Message;
    let recipient = messageData.Recipient.Address;
    let subject = messageData.Subject;
    let sendTime = messageData.SubmitTime;

    if (!recipient && !subject && !sendTime)
      return "Unknown Subject";

    // Format the message. Example:
    /*
     * 1234567890
     * Hello World
     * 12/31/2021, 12:00:00 PM
     */
    prettyMessage = "";
    if (recipient)
      prettyMessage += `${recipient}\n`;
    if (subject)
      prettyMessage += `${subject}\n`;
    if (sendTime && !isNaN(Date.parse(sendTime))) {
      sendTime = new Date(sendTime);
      sendTime = sendTime.toLocaleString('en-US', { timeZone: 'America/New_York' }); // EST/EDT
      prettyMessage += `${sendTime}`;
    }

    return prettyMessage;
  }

  /**
   * Formats the table to display the faxes.
   * @returns {JSX.Element} The table to display the faxes.
   */
  const printQueueTable = () => {
    let columnWidth = Math.floor(100 / keys.length) + "%";

    return (
      <Table>
        <thead>
          <tr>
            {
              keys.map((key, index) => {
                return <th key={index} scope="col" style={{ width: columnWidth }}>{key}</th>;
              })
            }
          </tr>
        </thead>
        <tbody>
          {getMessageQueues().map((queue, index) => {
            return (
              <tr key={index}>
                {
                  // Print table row
                  keys.map((key, index) => {
                    return <td key={index} style={{ width: columnWidth }}>{queue[key]}</td>;
                  })
                }
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <div className="FaxList">
      {printQueueTable()}
    </div>
  );
};

export default FaxList;
