import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import Login from './components/Login';
import SendFax from './components/SendFax';
import FaxList from './components/FaxList';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from "./components/Footer.js";
import AdminPanel from './components/AdminPanel'; // Import AdminPanel


import './styles.css'; // Ensure this import is correct based on your file structure
import "react-toastify/dist/ReactToastify.css";


export const FAX_SERVER = "https://cloud.faxback.net";

const App = () => {
  const [loginId, setLoginId] = useState(localStorage.getItem('loginId'));
  const [username, setUsername] = useState(localStorage.getItem('username'));

  /**
   * Clears the login details from the state and local storage.
   */
  const clearLoginDetails = () => {
    setLoginId(null);
    setUsername(null);
    localStorage.removeItem('loginId');
    localStorage.removeItem('username');
  };

  // Check if the login id is still valid and run the login process again if necessary
  useEffect(() => {
    /**
     * Refreshes the login id every minute.
     */
    const refreshLogin = async () => {
      if (loginId) {
        try {
          // Prepare the request
          const path = `${FAX_SERVER}/rest/nsx/RefreshId?LoginId=${loginId}`;

          // Send the request
          await axios.get(path);
          console.log("Login Id refresh successful");
        }
        catch (e) {
          console.error("Login Id refresh failed:", e.message);
          clearLoginDetails(); // Clear the state if the login id expires
        }
      }
    };

    // Refresh the login id every minute
    const interval = setInterval(refreshLogin, 60000);
    return () => clearInterval(interval);
  }, [loginId]);

  const isAuthenticated = loginId ? true : false;

  return (
    <Router>
      <div className="main-content">
        {
          // Only show the Navbar if the user is logged in
          loginId &&
            <Navbar loginId={loginId} logOut={clearLoginDetails} />
        }
        <ErrorBoundary>
          <div className="container">
            <Routes>
              <Route path="/" element={isAuthenticated ? <Navigate to="/inbox" /> : <Login setLoginId={setLoginId} setGlobalUsername={setUsername} />} />
              <Route path="/login" element={isAuthenticated ? <Navigate to="/inbox" /> : <Login setLoginId={setLoginId} setGlobalUsername={setUsername} />} />
              <Route path="/inbox" element={isAuthenticated ? <FaxList key="inbox" type="inbox" loginId={loginId} keys={["Receiving", "Received"]} /> : <Navigate to="/login" />} />
              <Route path="/sendfax" element={isAuthenticated ? <SendFax loginId={loginId} username={username} /> : <Navigate to="/login" />} />
              <Route path="/sent" element={isAuthenticated ? <FaxList key="sent" type="sent" loginId={loginId} keys={["Send", "Sending", "Sent"]} /> : <Navigate to="/login" />} />
              <Route path="/admin" element={isAuthenticated ? <AdminPanel /> : <Navigate to="/admin" />} /> {/* Add AdminPanel route */}
              
            </Routes>
          </div>
        </ErrorBoundary>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
