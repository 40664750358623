import axios from "axios"; // Import axios for making HTTP requests
import { toXML } from "jstoxml";
import React, { useState } from "react"; // Import React and necessary hooks
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { FAX_SERVER } from "../App";

import { FaPaperclip, FaPaperPlane, FaTrash } from "react-icons/fa"; // Import icons from react-icons


// Define the SendFax component, accepting loginId and username as props
const SendFax = ({ loginId, username }) => {
  const [from, setFrom] = useState(""); // Sender's name
  const [to, setTo] = useState(""); // Recipient's name
  const [subject, setSubject] = useState(""); // Subject of the fax
  const [faxNumber, setFaxNumber] = useState(""); // Recipient's fax number
  const [company, setCompany] = useState(""); // Sender's company (optional)
  const [appInfo] = useState("MCN Online Fax Portal"); // Application information
  const [message, setMessage] = useState(""); // Message to send
  const [faxFile, setFaxFile] = useState(null); // File to be uploaded
  const [inProgress, setInProgress] = useState(false);

  const navigate = useNavigate(); // React router navigation hook

  /**
   * Gets the fax details to send based on the user input.
   * @returns {Object} The fax details to send.
   */
  const getFaxDetailsByUserInput = async () => {
    // Create data from the input fields
    let faxDetails = {
      SendMessage: {
        AccountId: username,
        SenderName: from,
        Subject: subject,
        AppInfo: appInfo,
        Recipient: {
          Name: to,
          Address: faxNumber,
        },
        ActionTypes: "ClientOutbox", // Remove to automatically delete sent faxes
      },
    };

    // Add the optional fields
    if (company)
      faxDetails.SendMessage.SenderCompany = company;

    // Handle the file input
    if (faxFile != null) {
      let reader = new FileReader();
      reader.readAsDataURL(faxFile); // Convert the file to base64

      return new Promise((resolve) => {
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];

          // Create document data
          faxDetails.SendMessage.Document = {
            DocumentPart: 1, // Content
            DocumentType: 3, // PDF
            ContentData: base64String,
          };

          resolve(faxDetails); // Return a promise so the data has time to be converted to base64
        };
      });
    } else {
      // Just send a message if no file is uploaded
      faxDetails.SendMessage.Document = {
        DocumentPart: 0, // Cover Page
        ContentText: message ? message : "No message provided.",
      };
    }

    return Promise.resolve(faxDetails);
  };

  /**
   * Sends a fax.
   */
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const path = `${FAX_SERVER}/rest/mqs/Messages/SendMessage?LoginId=${loginId}`;

    // Prevent multiple submissions
    if (inProgress)
      return;
    setInProgress(true);
  
    // Send the fax
    try {
      // Send the request
      popUp("Sending fax...", "info");
      const requestData = toXML(
        { NSX: await getFaxDetailsByUserInput() },
        { indent: "    " }
      ); // Use XML so large files can be sent
      await axios.post(path, requestData, {
        headers: { "Content-Type": "application/xml" },
      });
      console.log("Fax sent"/*, requestData*/);
      popUp("Fax sent!", "success");

      // Redirect to the sent faxes page
      navigate("/sent");
    } catch (e) {
      console.error("Fax sending failed:", e.message);
      popUp(`Failed to send fax!\n${e.message}`, "error");
    }

    setInProgress(false); // Allow submissions again
  };

  /**
   * Deletes the attached file.
   */
  const handleDeleteFile = () => {
    setFaxFile(null); // TODO: Doesn't allow re-uploading the same file
  };

  /**
   * Display a pop-up message.
   * @param {string} message - The message to display.
   * @param {string} type - The type of toast to display.
   */
  const popUp = (message, type) => {
    toast.dismiss(); // Close existing pop-ups
    toast(message, { type: type }); // Pop-up
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Input fields for the fax details */}
      <input
        type="text"
        name="from"
        placeholder="From"
        value={from}
        onChange={(e) => setFrom(e.target.value)}
        required // Make this field required
      />
      <input
        type="text"
        name="company"
        placeholder="From: Company (Optional)"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      <input
        type="text"
        name="faxNumber"
        placeholder="To Fax Number xxx-xxx-xxxx"
        value={faxNumber}
        onChange={(e) => setFaxNumber(e.target.value)}
        pattern="\d{3}-\d{3}-\d{4}|\d{10}" // Pattern for fax number validation
        required // Make this field required
      />
      <input
        type="text"
        name="to"
        placeholder="To: (Name)"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        required // Make this field required
      />
      <input
        type="text"
        name="subject"
        placeholder="Subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        required // Make this field required
      />
      {
        // Show the file name if a file is uploaded
        faxFile ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input type="text" value={faxFile.name} readOnly style={{ flex: 1 }} />
            <button type="button" onClick={handleDeleteFile} className="icon-container icon-trash">
              <FaTrash style={{ color: '#fff' }} />
            </button>
          </div>
        ) : (
          // Otherwise, show the message input field
          <textarea
            name="message"
            placeholder="Message (Optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="textarea-large"
          />
        )
      }

      {/* Attach file button */}
      <label className="btn-hover btn-attach" style={{ marginRight: "10px" }}>
        <FaPaperclip /> Attach File
        <input
          type="file"
          name="faxFile"
          onChange={(e) => setFaxFile(e.target.files[0])}
          accept=".pdf,.doc,.docx,.html,.tif"
          style={{ display: "none" }}
        />
      </label>
      {!faxFile && (
        <small style={{ display: 'block', marginTop: '5px', color: '#888' }}>
          Accepted file types: .PDF; .DOC; .DOCX; .HTML; .TIF;
        </small>
      )}
  
      {/* Send fax button */}
      <div style={{ marginTop: "10px" }}>
        <button type="submit" className="btn-hover btn-send">
          <FaPaperPlane /> Send Fax
        </button>
      </div>
  
      {/* Container needed for pop-up messages */}
      <ToastContainer/>
    </form>
  );
};

export default SendFax; // Export the SendFax component as default
