import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { StatusCode } from "status-code-enum";

import { FAX_SERVER } from "../App";
import { config } from "../Config";
import logo from "../mcnonline_logo.png"; // Import the logo image


const Login = ({ setLoginId, setGlobalUsername }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate(); // React router navigation hook

  /**
   * Display a pop-up message.
   * @param {string} message - The message to display.
   * @param {string} type - The type of toast to display.
   */
  const popUp = (message, type) => {
    toast.dismiss(); // Close existing pop-ups
    toast(message, { type: type }); // Pop-up
  }

  /**
   * Handle the login form submission.
   * @param {Event} e - The form submission event
   */
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const route = `${config.devServer}/api/login`;

    // Prevent multiple submissions
    if (inProgress)
      return;
    setInProgress(true);

    // Log in
    try {
      // Send the request
      popUp("Logging in...", "info");
      const response = await axios.post(route, { username: username, password: password, faxServer: FAX_SERVER });

      // Get the login id
      let loginId = response.data.loginId;
      console.log("Login successful. Login id:", loginId);
      popUp("Logged in!", "success");

      // Update the global state
      setLoginId(loginId);
      setGlobalUsername(username);
      localStorage.setItem("loginId", loginId); // Store loginId in localStorage
      localStorage.setItem("username", username); // Store username in localStorage      

      // Redirect to the inbox
      navigate("/sendfax");
    }
    catch (e) {
      console.error("Login failed:", e.message);

      // Display an error pop-up
      let errorMsg;
      if (e.code === "ERR_NETWORK")
        errorMsg = "Couldn't connect to the server!\nPlease try again later.";
      else if (e.response && e.response.status === StatusCode.ClientErrorUnauthorized)
        errorMsg = "Login failed!\nPlease check your username and password.";
      else
        errorMsg = "Internal server error!\nPlease try again later.";
      popUp(errorMsg, "error");
    }

    setInProgress(false); // Allow further submissions
  }

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <img className="mcn-logo-login" src={logo} alt="Logo" />
      <h1 className="login-title">Fax Portal</h1>


      <form onSubmit={handleLogin}>
        {/* Username and password input fields */}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {/* Login button */}
        <button type="submit" className="btn-hover btn-login m-auto">
          Login
        </button>
      </form>

      {/* Container needed for pop-up messages */}
      <ToastContainer/>
    </div>
  );
};


export default Login;
